
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Home",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "",
    "label": "Comercial",
    "icon": "pi pi-briefcase",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/sectores",
        "label": "Sectores",
        "icon": "pi pi-wallet",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/clientes",
        "label": "Clientes",
        "icon": "pi pi-verified",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/clienteprocesos",
        "label": "Procesos cliente",
        "icon": "pi pi-calendar",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "",
    "label": "Generales",
    "icon": "pi pi-cog",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/geopaises",
        "label": "Paises",
        "icon": "pi pi-map-marker",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/geodepartamentos",
        "label": "Departamentos",
        "icon": "pi pi-map-marker",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/geociudades",
        "label": "Ciudades",
        "icon": "pi pi-map-marker",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/tipoidentificaciones",
        "label": "Identificaciones",
        "icon": "pi pi-id-card",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/tipodocumentos",
        "label": "Documentos",
        "icon": "pi pi-folder-open",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/cotizacionestados",
        "label": "Estado cotizaciones",
        "icon": "pi pi-database",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/auditoria",
        "label": "Auditoria",
        "icon": "pi pi-list",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "/usuarios",
    "label": "Usuarios",
    "icon": "pi pi-user-edit",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/usuarios",
        "label": "Usuarios",
        "icon": "pi pi-users",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/roles",
        "label": "Roles",
        "icon": "pi pi-tag",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  }
],
	estado: [    
{value: "Activo", label: "Activo"},
	{value: "Inactivo", label: "Inactivo"}
    ],
	facturaElectronica: [    
{value: "Si", label: "Si"},
	{value: "No", label: "No"}
    ],
	estadoActividad: [    
{value: "Sin_Asignar", label: "Sin_Asignar"},
	{value: "En_Proceso", label: "En_Proceso"},
	{value: "En_Pruebas", label: "En_Pruebas"},
	{value: "Entregado", label: "Entregado"}
    ],
	tipo: [    
{value: "Nota", label: "Nota"},
	{value: "Llamada", label: "Llamada"},
	{value: "Reunion", label: "Reunion"}
    ],
	estado2: [    
{value: "Realizada", label: "Realizada"},
	{value: "Pendiente", label: "Pendiente"}
    ],
	destino: [    
{value: "Clientes", label: "Clientes"},
	{value: "Cotizaciones", label: "Cotizaciones"}
    ],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}