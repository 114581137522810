
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//auditoria routes
			{
				path: '/auditoria/:fieldName?/:fieldValue?',
				name: 'auditorialist',
				component: () => import('./pages/auditoria/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/auditoria/view/:id', 
				name: 'auditoriaview', 
				component: () => import('./pages/auditoria/view.vue'), 
				props: true
			},
		

//clientecontactos routes
			{
				path: '/clientecontactos/:fieldName?/:fieldValue?',
				name: 'clientecontactoslist',
				component: () => import('./pages/clientecontactos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/clientecontactos/view/:id', 
				name: 'clientecontactosview', 
				component: () => import('./pages/clientecontactos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/clientecontactos/add', 
				name: 'clientecontactosadd', 
				component: () => import('./pages/clientecontactos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/clientecontactos/edit/:id', 
				name: 'clientecontactosedit', 
				component: () => import('./pages/clientecontactos/edit.vue'), 
				props: true
			},
		

//clienteprocesos routes
			{
				path: '/clienteprocesos/:fieldName?/:fieldValue?',
				name: 'clienteprocesoslist',
				component: () => import('./pages/clienteprocesos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/clienteprocesos/view/:id', 
				name: 'clienteprocesosview', 
				component: () => import('./pages/clienteprocesos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/clienteprocesos/add', 
				name: 'clienteprocesosadd', 
				component: () => import('./pages/clienteprocesos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/clienteprocesos/edit/:id', 
				name: 'clienteprocesosedit', 
				component: () => import('./pages/clienteprocesos/edit.vue'), 
				props: true
			},
		

//clientes routes
			{
				path: '/clientes/:fieldName?/:fieldValue?',
				name: 'clienteslist',
				component: () => import('./pages/clientes/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/clientes/view/:id', 
				name: 'clientesview', 
				component: () => import('./pages/clientes/view.vue'), 
				props: true
			},
		
			{ 
				path: '/clientes/add', 
				name: 'clientesadd', 
				component: () => import('./pages/clientes/add.vue'), 
				props: true
			},
	
			{ 
				path: '/clientes/edit/:id', 
				name: 'clientesedit', 
				component: () => import('./pages/clientes/edit.vue'), 
				props: true
			},
		

//cotizacionactividades routes
			{
				path: '/cotizacionactividades/:fieldName?/:fieldValue?',
				name: 'cotizacionactividadeslist',
				component: () => import('./pages/cotizacionactividades/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/cotizacionactividades/view/:id', 
				name: 'cotizacionactividadesview', 
				component: () => import('./pages/cotizacionactividades/view.vue'), 
				props: true
			},
		
			{ 
				path: '/cotizacionactividades/add', 
				name: 'cotizacionactividadesadd', 
				component: () => import('./pages/cotizacionactividades/add.vue'), 
				props: true
			},
	
			{ 
				path: '/cotizacionactividades/edit/:id', 
				name: 'cotizacionactividadesedit', 
				component: () => import('./pages/cotizacionactividades/edit.vue'), 
				props: true
			},
		

//cotizaciones routes
			{
				path: '/cotizaciones/:fieldName?/:fieldValue?',
				name: 'cotizacioneslist',
				component: () => import('./pages/cotizaciones/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/cotizaciones/view/:id', 
				name: 'cotizacionesview', 
				component: () => import('./pages/cotizaciones/view.vue'), 
				props: true
			},
		
			{ 
				path: '/cotizaciones/add', 
				name: 'cotizacionesadd', 
				component: () => import('./pages/cotizaciones/add.vue'), 
				props: true
			},
	
			{ 
				path: '/cotizaciones/edit/:id', 
				name: 'cotizacionesedit', 
				component: () => import('./pages/cotizaciones/edit.vue'), 
				props: true
			},
		

//cotizacionestados routes
			{
				path: '/cotizacionestados/:fieldName?/:fieldValue?',
				name: 'cotizacionestadoslist',
				component: () => import('./pages/cotizacionestados/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/cotizacionestados/view/:id', 
				name: 'cotizacionestadosview', 
				component: () => import('./pages/cotizacionestados/view.vue'), 
				props: true
			},
		
			{ 
				path: '/cotizacionestados/add', 
				name: 'cotizacionestadosadd', 
				component: () => import('./pages/cotizacionestados/add.vue'), 
				props: true
			},
	
			{ 
				path: '/cotizacionestados/edit/:id', 
				name: 'cotizacionestadosedit', 
				component: () => import('./pages/cotizacionestados/edit.vue'), 
				props: true
			},
		

//cotizacionseguimientos routes
			{
				path: '/cotizacionseguimientos/:fieldName?/:fieldValue?',
				name: 'cotizacionseguimientoslist',
				component: () => import('./pages/cotizacionseguimientos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/cotizacionseguimientos/view/:id', 
				name: 'cotizacionseguimientosview', 
				component: () => import('./pages/cotizacionseguimientos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/cotizacionseguimientos/add', 
				name: 'cotizacionseguimientosadd', 
				component: () => import('./pages/cotizacionseguimientos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/cotizacionseguimientos/edit/:id', 
				name: 'cotizacionseguimientosedit', 
				component: () => import('./pages/cotizacionseguimientos/edit.vue'), 
				props: true
			},
		
			{
				path: '/cotizacionseguimientos/list_home/:fieldName?/:fieldValue?',
				name: 'cotizacionseguimientoslist_home',
				component: () => import('./pages/cotizacionseguimientos/list_home.vue'), 
				props: route => passRouteToProps(route)
			},
	

//geociudades routes
			{
				path: '/geociudades/:fieldName?/:fieldValue?',
				name: 'geociudadeslist',
				component: () => import('./pages/geociudades/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/geociudades/view/:id', 
				name: 'geociudadesview', 
				component: () => import('./pages/geociudades/view.vue'), 
				props: true
			},
		
			{ 
				path: '/geociudades/add', 
				name: 'geociudadesadd', 
				component: () => import('./pages/geociudades/add.vue'), 
				props: true
			},
	
			{ 
				path: '/geociudades/edit/:id', 
				name: 'geociudadesedit', 
				component: () => import('./pages/geociudades/edit.vue'), 
				props: true
			},
		

//geodepartamentos routes
			{
				path: '/geodepartamentos/:fieldName?/:fieldValue?',
				name: 'geodepartamentoslist',
				component: () => import('./pages/geodepartamentos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/geodepartamentos/view/:id', 
				name: 'geodepartamentosview', 
				component: () => import('./pages/geodepartamentos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/geodepartamentos/add', 
				name: 'geodepartamentosadd', 
				component: () => import('./pages/geodepartamentos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/geodepartamentos/edit/:id', 
				name: 'geodepartamentosedit', 
				component: () => import('./pages/geodepartamentos/edit.vue'), 
				props: true
			},
		

//geopaises routes
			{
				path: '/geopaises/:fieldName?/:fieldValue?',
				name: 'geopaiseslist',
				component: () => import('./pages/geopaises/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/geopaises/view/:id', 
				name: 'geopaisesview', 
				component: () => import('./pages/geopaises/view.vue'), 
				props: true
			},
		
			{ 
				path: '/geopaises/add', 
				name: 'geopaisesadd', 
				component: () => import('./pages/geopaises/add.vue'), 
				props: true
			},
	
			{ 
				path: '/geopaises/edit/:id', 
				name: 'geopaisesedit', 
				component: () => import('./pages/geopaises/edit.vue'), 
				props: true
			},
		

//permisos routes
			{
				path: '/permisos/:fieldName?/:fieldValue?',
				name: 'permisoslist',
				component: () => import('./pages/permisos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/permisos/view/:id', 
				name: 'permisosview', 
				component: () => import('./pages/permisos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/permisos/add', 
				name: 'permisosadd', 
				component: () => import('./pages/permisos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/permisos/edit/:id', 
				name: 'permisosedit', 
				component: () => import('./pages/permisos/edit.vue'), 
				props: true
			},
		

//permisoslist routes

//roles routes
			{
				path: '/roles/:fieldName?/:fieldValue?',
				name: 'roleslist',
				component: () => import('./pages/roles/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/roles/view/:id', 
				name: 'rolesview', 
				component: () => import('./pages/roles/view.vue'), 
				props: true
			},
		
			{ 
				path: '/roles/add', 
				name: 'rolesadd', 
				component: () => import('./pages/roles/add.vue'), 
				props: true
			},
	
			{ 
				path: '/roles/edit/:id', 
				name: 'rolesedit', 
				component: () => import('./pages/roles/edit.vue'), 
				props: true
			},
		

//sectores routes
			{
				path: '/sectores/:fieldName?/:fieldValue?',
				name: 'sectoreslist',
				component: () => import('./pages/sectores/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/sectores/view/:id', 
				name: 'sectoresview', 
				component: () => import('./pages/sectores/view.vue'), 
				props: true
			},
		
			{ 
				path: '/sectores/add', 
				name: 'sectoresadd', 
				component: () => import('./pages/sectores/add.vue'), 
				props: true
			},
	
			{ 
				path: '/sectores/edit/:id', 
				name: 'sectoresedit', 
				component: () => import('./pages/sectores/edit.vue'), 
				props: true
			},
		

//tipodocumentos routes
			{
				path: '/tipodocumentos/:fieldName?/:fieldValue?',
				name: 'tipodocumentoslist',
				component: () => import('./pages/tipodocumentos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tipodocumentos/view/:id', 
				name: 'tipodocumentosview', 
				component: () => import('./pages/tipodocumentos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tipodocumentos/add', 
				name: 'tipodocumentosadd', 
				component: () => import('./pages/tipodocumentos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tipodocumentos/edit/:id', 
				name: 'tipodocumentosedit', 
				component: () => import('./pages/tipodocumentos/edit.vue'), 
				props: true
			},
		

//tipoidentificaciones routes
			{
				path: '/tipoidentificaciones/:fieldName?/:fieldValue?',
				name: 'tipoidentificacioneslist',
				component: () => import('./pages/tipoidentificaciones/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tipoidentificaciones/view/:id', 
				name: 'tipoidentificacionesview', 
				component: () => import('./pages/tipoidentificaciones/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tipoidentificaciones/add', 
				name: 'tipoidentificacionesadd', 
				component: () => import('./pages/tipoidentificaciones/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tipoidentificaciones/edit/:id', 
				name: 'tipoidentificacionesedit', 
				component: () => import('./pages/tipoidentificaciones/edit.vue'), 
				props: true
			},
		

//usuarios routes
			{
				path: '/usuarios/:fieldName?/:fieldValue?',
				name: 'usuarioslist',
				component: () => import('./pages/usuarios/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/usuarios/view/:id', 
				name: 'usuariosview', 
				component: () => import('./pages/usuarios/view.vue'), 
				props: true
			},
		
			{ 
				path: '/index/register', 
				name: 'usuariosuserregister', 
				component: () => import('./pages/index/userregister.vue'), 
				props: true
			},
	
			{ 
				path: '/account/edit', 
				name: 'usuariosaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'usuariosaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/usuarios/add', 
				name: 'usuariosadd', 
				component: () => import('./pages/usuarios/add.vue'), 
				props: true
			},
	
			{ 
				path: '/usuarios/edit/:id', 
				name: 'usuariosedit', 
				component: () => import('./pages/usuarios/edit.vue'), 
				props: true
			},
		

	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (authRequired) {
			if(!user){
				return next({ path: '/',  query: { nexturl: to.fullPath } });
			}
			//authorize user
			if (!auth.canView(path)) {
				return next({path: "/error/forbidden"});
			}
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

 		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');
		
		next();
	});

	return router;
}
